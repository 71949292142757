/* 
:root {
  --animation-speed: 40s;
}

@keyframes scroll {
    0% { transform: translateX(0%); }
    100% { transform: translateX(calc(-380px * var(--item-count))); }
}

.slider {
    background: linear-gradient(to left, #fcc419, #f59e0b);
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    padding: 8PX;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100vw;
    height: 7vh;

    &::before,
    &::after {
        background-color: linear-gradient(to left, #fcc419, #f59e0b);
        content: "";
        height: 100px;
        position: absolute;
        width: 200px;
        z-index: 2;
    }

    &::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    &::before {
        left: 0;
        top: 0;
    }

    .slide-track {
        animation: scroll var(--animation-speed) linear infinite;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(380px * var(--item-count));
    }

    .slide {
      width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 4px;
    }

    img {
        max-height: 100%;
        max-width: 100%;
    }
    p{
      font-size: 20px;
    }

}
.home-hero-description {
  box-shadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)"
}  */

.slider{
background: linear-gradient(88deg, #EC9F04 -13.21%, #FCF32D 148.7%);
box-shadow: 0px 4px 20px -1px rgba(0, 0, 0, 0.00);
backdrop-filter: blur(25px);

}
