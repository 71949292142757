@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x:hidden;
  background: white;
}

.monstrrate {
  font-family: Montserrat;
}
.poppins{
  font-family: poppins;
}
.notoSans {
  font-family: Noto Sans;
}
.nunito {
  font-family: Nunito;
}
.roboto {
  font-family: Roboto;
}.railway{
  font-family: Railway;
}


